import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import HeadingGroup, {
  type HeadingGroupProps,
} from '@/components/base/modules/HeadingGroup/HeadingGroup';

export interface SectionHeaderProps extends HeadingGroupProps {}

export default function SectionHeader(props: SectionHeaderProps) {
  const colProps = {
    center: props.center,
    span: {xs: 4, sm: 8, md: 10, lg: 8, xl: 8},
  };
  const componentName = 'section-header';

  return (
    <Grid data-component-name={componentName}>
      <Col {...colProps}>
        <HeadingGroup {...props} />
      </Col>
    </Grid>
  );
}
